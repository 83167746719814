<template>
    <div>
    
        <div v-if="!pgLoading && hasMore" 
            class="loadMore text-center form-group">
            <button 
                class="btn-blue h45 uppercase"
                type="button"
                :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                :disabled="btnLoading"
                @click="loadMore()">
                <span v-if="btnLoading">
                    <div class="loaderN loaderN-md mauto mt-15" style='margin-left:-15px'></div>
                </span>
                <span v-if="!btnLoading">
                    {{ $t('app.loadMore') }}
                </span>
            </button>
        </div> 
        
    </div>
</template>

<script>    
export default {
    name: 'LoadMoreButton',
    props: [
        'pgLoading',
        'hasMore',
        'btnLoading'
    ],
    data() {
        return {
            //
        }
    },
    mounted() {},
    created() {},
    methods: {

        loadMore(event) {
            this.$emit('loadMoreClicked', event)
        },
    }
}
</script>